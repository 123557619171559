<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div id="container"></div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, useIonRouter } from "@ionic/vue";
import { defineComponent } from "vue";

import http from "@/services/http.service.js";

export default defineComponent({
  name: "Home",
  components: {
    IonContent,
    IonPage,
  },
  setup() {
    return { router: useIonRouter() };
  },
  beforeCreate() {
    const user = window.localStorage.getItem("user");
    if (user) {
      try {
        this.$store.dispatch("global/setAuthCredentials", JSON.parse(user));
      } catch (e) {
        // Do nothing
      }
    }

    http
      .auth()
      .then(() => {
        this.$store.dispatch("global/setAuth", true);
      })
      .catch(() => {
        this.$store.dispatch("global/setAuth", false);
        this.$store.dispatch("global/unsetAuthCredentials");
        window.localStorage.removeItem("user");
      })
      .finally(() => {
        this.$store.dispatch("global/setIsInitialized", true);
        let urlParams = new URLSearchParams(window.location.search);
        let params = null;
        if (urlParams.has("video")) {
          params = {
            video: urlParams.get("video"),
          };
        }
        this.router.push({ path: "/home", query: params });
      });
  },
});
</script>

<style scoped>
#container {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
</style>
